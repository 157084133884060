  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/1-3-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-construction/'>Expertise construction</a></li><li>Expertise Humidité</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>L’expertise humidité : assistance technique pour éradiquer les problèmes d’humidité.</h1>

<p>L’expertise humidité vise à aider le propriétaire ou le locataire à déterminer les causes et origines d’un sinistre humidité afin de définir des solutions techniques pérennes pour éradiquer les problèmes d’humidité dans une habitation.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expertise Humidité" />
</div>


<p>Généralement, l’humidité se caractérise par : </p>
<ul><li>tâches noires au bas des murs,</li>
<li>tâches verdâtres au plafond,</li>
<li>présence de salpêtre sur mur,</li>
<li>buée et condensation prolongée,</li>
<li>diffusion d’humidité dans un plancher, une toiture terrasse</li></ul>

<p>L’humidité récurrente dans un bâtiment constitue une source de problèmes pouvant non seulement affecter à terme les matériaux du bâti (corrosion, altération, pertes mécaniques) mais également être source de maladies graves pour l’occupant. </p>

<h2>Les conséquences de l’humidité dans un logement</h2>

<p>Si les causes et origines ne sont pas déterminées puis traitées, l’humidité peut s’aggraver et se propager pour aggraver les désordres dans votre habitation (altération des matériaux, perte des caractéristiques technique et mécaniques des matériaux) et entraîner des pathologies dans votre bien mais également, à terme, des soucis de santé.</p>

<h2>Notre prestation expertise humidité </h2>
<h2>Le cabinet RV Expertises a fait des problématiques d’humidité sa spécialité. </h2>
<p>Nous employons un outillage spécifique et adapté (détecteur d’humidité, détecteur de fuite, inspection télévisée des réseaux) afin de mettre en œuvre tous les moyens nécessaires pour détecter les origines et sources d’humidité dans votre logement. </p>

<p>Si vous constatez des signes d’humidité récurrents, il est important d’agir rapidement et de contacter un Expert afin de résoudre rapidement les problèmes et d’éviter une aggravation de ceux-ci.</p>


<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Demandez un devis</a>
    </div>
</div>
                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-construction/expertise-humidite/' className='active'>Expertise Humidité</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details